import React from "react";

import PageWrapper from "../../Atoms/PageWrapper";
import FlexWrapper from "../../Atoms/FlexWrapper";
import Typography from "../../Atoms/Typography";
import { Colors } from "../../Utils/Colors";

import "./ContactUs.css";
import PhoneBig from "../../Assets/Phone_Big.svg";
import EmailBig from "../../Assets/Email_Big.svg";
import Location from "../../Assets/Location.svg";
import GetInTouch from "../../Template/GetInTouch";

import hexToRGBA from "../../Utils/hexToRGBA";
import MarginWrapper from "../../Atoms/MarginWrapper";
const ContactUs = () => {
  const isMobile = window.innerWidth <= 700;

  const imageStyles = {
    width: isMobile ? "20px" : "28px",
    height: isMobile ? "20px" : "28px",
  };

  const locationCard = (imgSrc, header, content) => {
    return (
      <FlexWrapper flexDirection="column" gap={"12px"}>
        <FlexWrapper
          width={"50px"}
          height={"50px"}
          border={`1px solid ${Colors.MirageBlack}`}
          borderRadius={"50%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img src={imgSrc} alt="" width={"30px"} height={"30px"} />
        </FlexWrapper>
        <div>
          <Typography
            text={header}
            sizeAndWeight={"24px-500"}
            color={Colors.MirageBlack}
            textAlign={"left"}
          />
          <MarginWrapper mt="2px" />
          <div>
            {content.map((each) => {
              return (
                <Typography
                  key={each}
                  text={each}
                  sizeAndWeight={"16px-400"}
                  color={hexToRGBA(Colors.MirageBlack, "0.4")}
                  textAlign={"left"}
                />
              );
            })}
          </div>
        </div>
      </FlexWrapper>
    );
  };
  return (
    <PageWrapper>
      <MarginWrapper ml={isMobile ? "16px" : "6vh"}>
        <Typography
          sizeAndWeight={isMobile ? "2rem-500" : "4rem-500"}
          color={Colors.PictonBlue}
          textAlign={isMobile ? "center" : "left"}
          text={"Contact Us"}
        />
      </MarginWrapper>
      <FlexWrapper
        flexDirection={isMobile ? "column" : "row"}
        margin={isMobile ? "16px" : "6vh 4rem"}
        gap={"4rem"}
        flexWrap={"wrap"}
        justifyContent={"space-around"}
        flexPercentage={1}
      >
        <FlexWrapper
          flexDirection="column"
          flexPercentage={1}
          justifyContent="center"
          maxWidth={"500px"}
        >
          <FlexWrapper
            gap={isMobile ? "8px" : "6vh"}
            margin={"4vh 0"}
            flexDirection={"row"}
            flexWrap={"wrap"}
          >
            {locationCard(PhoneBig, "Phone number", ["+91 9972926500"])}
            {locationCard(EmailBig, "Email", ["rajesh.cherukuri@zeits.in"])}
            {locationCard(Location, "Address", [
              "Phase-IV,",
              "Road No. 72  Jeedimetla",
              "Hyderabad-55, Telangana",
            ])}
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper
          flexPercentage={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <iFrame
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3135.5722355436737!2d78.43599988686269!3d17.520901315362472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb901ebe08a36d%3A0x4fbcc61306911077!2sSJ%20Automation%20Solutions!5e0!3m2!1sen!2sin!4v1650911125170!5m2!1sen!2sin"
            width={"100%"}
            style={{ border: 0 }}
            loading="lazy"
            className="gmap_canvas"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          ></iFrame>
        </FlexWrapper>
      </FlexWrapper>
    </PageWrapper>
  );
};

export default ContactUs;
