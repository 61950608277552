import React from "react";
import AboutUs from "../../Template/AboutUs";
import BriefHistory from "../../Template/BriefHistory";
import ChooseUs from "../../Template/ChooseUs";
import Team from "../../Template/Team";

const About = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <AboutUs scrollToTop={scrollToTop} />
      <BriefHistory />
      <Team />
      <ChooseUs />
    </div>
  );
};

export default About;
