import React from "react";
import Clients from "../../Template/Clients";
import Services from "../../Template/Services";
import Testimonials from "../../Template/Testimonials";
import Introduction from "../../Template/Introduction";
const HomePage = () => {
  return (
    <div>
      <Introduction />
      <Services />
      <Clients />
      <Testimonials />
      {/* <img src={Img} alt="" />
      <Services />
      <Clients />
      https://assets6.lottiefiles.com/packages/lf20_xsicerbj.json */}
    </div>
  );
};

export default HomePage;
