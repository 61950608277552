import React, { useState, useEffect } from "react";
import JsonData from "./data/data.json";
import "./App.css";

import HomePage from "./Pages/HomePage";
import ContactUs from "./Pages/ContactUs";
import About from "./Pages/About";
import Header from "./Atoms/Header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./Template/Footer";
import Services from "./Template/Services";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const routes = [
    { path: "/", component: HomePage, exact: true },
    { path: "/about", component: About },
    { path: "/contact-us", component: ContactUs },
    { path: "/services/:someParam?", component: Services },
  ];

  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          {routes.map((route, idx) => (
            <Route key={idx} {...route} />
          ))}
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

/* <Navigation />
                                          <Header data={landingPageData.Header} />
                                          <Features data={landingPageData.Features} />
                                          <About data={landingPageData.About} />
                                          <Services data={landingPageData.Services} />
                                          <Gallery data={landingPageData.Gallery} />
                                          <Testimonials data={landingPageData.Testimonials} />
                                          <Team data={landingPageData.Team} />
                                          <Contact data={landingPageData.Contact} /> */
