import React from "react";

const PageWrapper = (props) => {
  const isMobile = window.innerWidth <= 600;
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
      }}
    >
      <div
        style={{
          position: "relative",
          maxWidth: isMobile ? "100%" : "1360px",
          margin: "auto",
          padding: props.px || isMobile ? "20px" : "4rem",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default PageWrapper;
