import React from "react";
import Button from "../../Atoms/Button";
import FlexWrapper from "../../Atoms/FlexWrapper";
import PageWrapper from "../../Atoms/PageWrapper";
import Title from "../../Atoms/Title/Title";
import Typography from "../../Atoms/Typography";
import { Colors } from "../../Utils/Colors";
import { isMobile } from "../../Utils/IsMobile";
import Logo from "../../Assets/Logo.jpg";
import MarginWrapper from "../../Atoms/MarginWrapper";

const AboutUs = ({ scrollToTop }) => {
  return (
    <PageWrapper>
      <Title text={"About us"} />
      <FlexWrapper
        flexDirection={isMobile ? "column" : "row"}
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"start"}
        gap={"10vw"}
        margin={isMobile ? "8vw 0" : "6vw 0"}
      >
        <FlexWrapper
          flexDirection="column"
          gap={"24px"}
          flexPercentage={"1"}
          justifyContent={"center"}
        >
          <MarginWrapper mt={"-20px"}>
            <Typography
              text={"Our Time , Your Service"}
              color={Colors.BigStone}
              sizeAndWeight={isMobile ? "1.2rem-500" : "2rem-500"}
              textAlign={isMobile ? "center" : "flex-start"}
            />
          </MarginWrapper>
          <Typography
            text={`providing innovative solutions to increase the efficiency of your business through Automation,IoT, Robotics, Vision systems,SPM manufacturing and Software development.\n\nProviding customized solutions to our clients is our strength. We are proud to announce  that we are equipped with such  a technical crew and technology.\n\nWe are providing solutions from Mechanical shop floor to Software development.We understand the need for convergence of technology in these Industry 4.0 revolution. So our Zeits team has a strong  team of engineering.\n\n Not only service, we are working on developing our own products also.
              `}
            color={Colors.BigStone}
            sizeAndWeight={isMobile ? "1rem-400" : "1rem-400"}
            whiteSpace={"pre-wrap"}
          />
          <Button
            buttonText={"Get In Touch"}
            onPress={scrollToTop}
            background={Colors.Red}
            textColor={Colors.White}
            height="45px"
            sizeAndWeight={"16-500"}
            borderColor={"20px"}
            borderRadius={"8px"}
            width={"120px"}
            margin={isMobile ? "auto" : "0"}
          />
        </FlexWrapper>
        <FlexWrapper flexPercentage={1}>
          <img src={Logo} alt="" width={"90%"} />
        </FlexWrapper>
      </FlexWrapper>
    </PageWrapper>
  );
};

export default AboutUs;
