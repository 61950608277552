import React from "react";

import PageWrapper from "../../Atoms/PageWrapper";
import FlexWrapper from "../../Atoms/FlexWrapper";
import MarginWrapper from "../../Atoms/MarginWrapper";

import "./Clients.css";
import Client1 from "../../Assets/Client1.jpg";
import Client2 from "../../Assets/Client2.jpg";
import Client3 from "../../Assets/Client3.jpg";
import Client4 from "../../Assets/Client4.jpg";
import Client5 from "../../Assets/Client5.jpg";
import Client6 from "../../Assets/Client6.png";
import Client7 from "../../Assets/Client7.png";

import Title from "../../Atoms/Title/Title";
import { isMobile } from "../../Utils/IsMobile";

const Clients = () => {
  const imageSource = [
    Client1,
    Client2,
    Client3,
    Client4,
    // Client5,
    Client6,
    Client7,
  ];

  return (
    <PageWrapper>
      <Title text={"Clients"} />
      <div className="wrapper">
        <div className="marquee">
          {imageSource.map((eachSource, index) => {
            return (
              <div className="clientImage" key={index} id={`${index}`}>
                <img
                  src={eachSource}
                  alt=""
                  width={"100%"}
                  height={"100%"}
                  style={{
                    maxWidth: isMobile ? "220px" : "150px",
                    width: isMobile ? "25vw" : "14vw",
                  }}
                />
              </div>
            );
          })}
          {imageSource.map((eachSource, index) => {
            return (
              <div className="clientImage" key={index}>
                <img
                  src={eachSource}
                  alt=""
                  width={"100%"}
                  height={"100%"}
                  style={{
                    maxWidth: isMobile ? "220px" : "150px",
                    width: isMobile ? "25vw" : "14vw",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Clients;
