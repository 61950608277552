const emailKey = {
  user_id: "user_xeUzLLjJbwY5778vcbyGO",
  service_id: "service_6a96ht6",
  template_id: "template_o61xyld",
};

export const anotherEmail = {
  user_id: "user_qMGDroZh7uLIRZVtlSq9z",
  service_id: "service_081c16v",
  template_id: "template_anaft3i",
};

export default emailKey;
