import React, { useState, useEffect } from "react";
import FlexWrapper from "../../Atoms/FlexWrapper";
import MarginWrapper from "../../Atoms/MarginWrapper";
import Typography from "../../Atoms/Typography";
import { testimonialsDetails } from "../../data/Testimonials";
import { Colors } from "../../Utils/Colors";
import { isMobile } from "../../Utils/IsMobile";
import Quote from "../../Assets/Quote.svg";

let nodeJsTimer;
const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (testimonialsDetails.length > 0) {
      handelSlider();
      return () => {
        clearInterval(nodeJsTimer);
      };
    }
  }, [testimonialsDetails]);

  const handelSlider = () => {
    nodeJsTimer = setInterval(() => {
      setActiveIndex((previous) => {
        if (testimonialsDetails.length - 1 === previous) {
          return 0;
        }
        return previous + 1;
      });
    }, 4000);
  };

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div
      onMouseEnter={() => {
        clearInterval(nodeJsTimer);
      }}
      onMouseLeave={() => {
        handelSlider();
      }}
    >
      <div>
        {testimonialsDetails.map((each, idx) => {
          return (
            <FlexWrapper
              flexDirection="column"
              key={idx}
              height={"240px"}
              width={"100%"}
              maxWidth={isMobile ? "100%" : "800px"}
              margin={"auto"}
              display={idx === activeIndex ? "flex" : "none"}
            >
              <img
                src={Quote}
                alt=""
                width={isMobile ? "30px" : "40px"}
                height={isMobile ? "30px" : "40px"}
              />
              <MarginWrapper mt={"12px"} mb={"12px"}>
                <Typography
                  text={each.testimonials}
                  color={Colors.MirageBlack}
                  sizeAndWeight={isMobile ? "1rem-400" : "1.4rem-400"}
                />
              </MarginWrapper>
              <FlexWrapper
                flexDirection="row"
                alignItems={"center"}
                gap={"4px"}
              >
                <Typography
                  text={each.clientName}
                  color={Colors.PictonBlue}
                  sizeAndWeight={"1rem-400"}
                />
                <Typography
                  text={each.occupation}
                  color={Colors.MirageBlack}
                  sizeAndWeight={"1rem-400"}
                />
              </FlexWrapper>
            </FlexWrapper>
          );
        })}
      </div>

      <FlexWrapper alignItems={"center"} justifyContent={"center"} gap={"6px"}>
        {testimonialsDetails.map((each, idx) => {
          const isSelected = activeIndex === idx;
          return (
            <div
              key={idx}
              style={{
                width: isSelected ? "28px" : "20px",
                height: "5px",
                borderRadius: "4px",
                background: isSelected ? Colors.Red : Colors.Grey,
                cursor: "pointer",
                transition: "0.9s all ease-in-out",
              }}
              onClick={() => handleClick(idx)}
            />
          );
        })}
      </FlexWrapper>
    </div>
  );
};

export default Carousel;
