import * as React from "react";

import Typography from "../Typography";
// import Spinner from "../S";
import { Colors } from "../../Utils/Colors";
import Spinner from "../../Assets/Spinner.svg";
import ClassNames from "./Button.module.css";

const Button = ({
  buttonText,
  onPress,
  disable = false,
  background,
  textColor,
  height,
  sizeAndWeight = "14-500",
  borderColor,
  border = "none",
  borderRadius,
  isLoading,
  maxWidth,
  error,
  width,
  boxShadow,
  opacity,
  changeOpacityWhenDisabled = true,
  margin,
  padding,
}) => {
  /**
   * if button text type is string returns typography else it returns the buttonText prop
   * @returns button children
   */
  const getButtonChildren = () => {
    if (isLoading) {
      return <img src={Spinner} alt="" />;
    } else if (typeof buttonText === "string") {
      return (
        <Typography
          text={error ? "Retry" : buttonText}
          color={textColor}
          sizeAndWeight={sizeAndWeight}
        />
      );
    } else {
      return buttonText;
    }
  };

  /**
   * based on the button disability
   * and based on `changeOpacityWhenDisabled` prop value
   * it calculates the required classes for the button
   * @returns all the required classes for the button
   */
  const getButtonClasses = () => {
    let classes = "";
    if (disable) {
      classes = `${ClassNames.disabledButtonCursor}`;
    }
    if (disable && changeOpacityWhenDisabled) {
      classes = `${classes} ${ClassNames.opacityOnDisable}`;
    }

    return `${classes} ${ClassNames.buttonStyles}`;
  };

  return (
    <button
      onClick={onPress}
      disabled={isLoading ? true : disable}
      className={getButtonClasses()}
      style={{
        background,
        width,
        height,
        border,
        borderColor,
        borderRadius,
        maxWidth,
        boxShadow,
        opacity,
        margin,
        padding,
      }}
    >
      {getButtonChildren()}
    </button>
  );
};

export default Button;
