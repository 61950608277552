export const testimonialsDetails = [
  {
    testimonials:
      "We have had a highly productive and mutually beneficial partnership with Ayla Networks. As a result of this technology strategy, we have been able to garner valuable insights on IoT devices for predictive maintenance leading to a more reliable consumer experience.",
    clientName: "Sensa Core",
    company: "",
    occupation: "MD",
  },
  {
    testimonials:
      "Zeit as a company are fantastic, great people and great support!",
    clientName: "NOVA",
    company: "LOOP",
    occupation: "CEO",
  },
  {
    testimonials:
      "Their attention to detail and Their attention to detail and willingness to do that one extra iteration to get it right was impressive.",
    clientName: "Jinchen",
    company: "MD",
    occupation: "CSO",
  },
  {
    testimonials:
      "Communication was regular and clear. Queries raised by us were answered promptly and with a high level of professionalism.",
    clientName: "SILGAZ",
    company: "Zeit",
    occupation: "MD",
  },
];
