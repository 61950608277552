const briefHistoryDetails = [
  {
    month: "JAN",
    year: "2021",
    achievement: "We ZEIT SYSTEMS started in ",
  },
  {
    month: "Sensa Core",
    year: "",
    achievement: "SPM Machines for Medical Equipment Manufacturers ",
  },
  {
    month: "AGI clozures",
    year: "",
    achievement: "Vision System Inspection in Plastic Industry",
  },
  {
    month: "Jinchen",
    year: "",
    achievement: "Machine Installaton support",
  },
  {
    month: "REITZ INDIA",
    year: "",
    achievement: "Developed automated welding applications",
  },
  {
    month: "Secure Industries",
    year: "",
    achievement: "Developed plastics assembly machines programming.",
  },
];

export default briefHistoryDetails;
