import React, { useState } from "react";

import PageWrapper from "../../Atoms/PageWrapper";
import FlexWrapper from "../../Atoms/FlexWrapper";
import { Colors } from "../../Utils/Colors";
import Typography from "../../Atoms/Typography";
import { Link } from "react-router-dom";

import GetInTouch from "../../Template/GetInTouch";
import MarginWrapper from "../../Atoms/MarginWrapper";
import { isMobile } from "../../Utils/IsMobile";
const Footer = () => {
  const quickLinks = [
    { title: "Home", link: "/" },
    { title: "About Zeit Systems", link: "/about" },
    { title: "Contact Us", link: "/contact" },
  ];

  const services = [
    { title: "Factory Automation", link: "/services/factory-automation" },
    { title: "Internet of Things", link: "/services/iot" },
    {
      title: "IoT Specialized Software development",
      link: "/services/iot-software",
    },
    { title: "Machine Vision", link: "/services/machine-vision" },
    { title: "Robotics", link: "/services/robotics" },
    { title: "SPM Manufacturing", link: "/services/spm" },
    { title: "Sales", link: "/services/sales" },
  ];

  const HoverTest = ({ text }) => {
    const [isHover, setHover] = useState(false);
    return (
      <div
        style={{
          fontSize: "0.875rem",
          fontWeight: "700",
          textDecoration: isHover ? "underline" : "none",
          color: "#FFF",
          textDecorationColor: Colors.yellow,
          textUnderlinePosition: "under",
          textUnderlineOffset: "2px",
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {text}
      </div>
    );
  };

  const Title = (name) => {
    return (
      <div style={{ marginBottom: "1rem" }}>
        <Typography
          text={name}
          color={Colors.White}
          sizeAndWeight={"1rem-700"}
        />
      </div>
    );
  };

  if (isMobile) {
    return (
      <div
        style={{
          background: Colors.BigStone,
          padding: isMobile ? "20px" : "0px",
        }}
      >
        <PageWrapper>
          <GetInTouch />
        </PageWrapper>
      </div>
    );
  }

  const servicesJSX = (
    <div>
      {Title("Services")}
      <FlexWrapper flexDirection="column" gap={"1.5rem"}>
        {services.map((data, idx) => (
          <Link
            style={{ textDecoration: "none" }}
            to={data.link}
            key={data.link}
          >
            <HoverTest text={data.title} />
          </Link>
        ))}
      </FlexWrapper>
    </div>
  );

  const isTab = window.innerWidth <= 850;

  return (
    <div
      style={{
        background: Colors.BigStone,
        padding: isMobile ? "20px" : "0px",
      }}
    >
      <PageWrapper>
        <FlexWrapper
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
        >
          <div>
            {Title("Quick Links")}
            <FlexWrapper flexDirection="column" gap={"1.5rem"}>
              {quickLinks.map((data, idx) => (
                <Link
                  style={{ textDecoration: "none" }}
                  to={data.link}
                  key={data.link}
                >
                  {/* <Typography
                    text={data.title}
                    color={Colors.White}
                    sizeAndWeight={"0.875rem-700"}
                  /> */}
                  <HoverTest text={data.title} />
                </Link>
              ))}
            </FlexWrapper>
            {isTab ? (
              <MarginWrapper mt={"40px"}>{servicesJSX}</MarginWrapper>
            ) : null}
          </div>
          {isTab ? null : servicesJSX}
          <GetInTouch />
        </FlexWrapper>
      </PageWrapper>
    </div>
  );
};

export default Footer;

{
  /* <FlexWrapper flexDirection="row" gap={"1.5rem"}>
              <img src={Location} alt="" />
              <Typography
                text={"location"}
                color={Colors.White}
                sizeAndWeight={"0.875rem-700"}
              />
            </FlexWrapper>
            <FlexWrapper flexDirection="row" gap={"1.5rem"}>
              <img src={PhoneImage} alt="" />
              <Typography
                text={"phone"}
                color={Colors.White}
                sizeAndWeight={"0.875rem-700"}
              />
            </FlexWrapper>
            <FlexWrapper flexDirection="row" gap={"1.5rem"}>
              <img src={EmailImage} alt="" />
              <Typography
                text={"email"}
                color={Colors.White}
                sizeAndWeight={"0.875rem-700"}
              />
            </FlexWrapper> */
}
