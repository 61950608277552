import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Colors } from "../../Utils/Colors";

import Hamburger from "../../Assets/Hamburger.svg";
import Close from "../../Assets/Close.svg";
import FlexWrapper from "../FlexWrapper";
import Modal from "../Modal";
import MarginWrapper from "../MarginWrapper";
import Typography from "../Typography";
import Logo from "../../Assets/logoWithOutCaption.jpg";

const MobileNavigation = ({ currentPage, boxShadow }) => {
  const [showRoutes, setShowRoutes] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const navigation = [
    { title: "Home", link: "/" },
    { title: "About", link: "/about" },
    { title: "Services", link: "/services" },
    { title: "Contact Us", link: "/contact-us" },
  ];
  const services = [
    { title: "Factory Automation", link: "/services/factory-automation" },
    { title: "Internet of Things", link: "/services/iot" },
    {
      title: "IoT Specialized Software development",
      link: "/services/iot-software",
    },
    { title: "Machine Vision", link: "/services/machine-vision" },
    { title: "Robotics", link: "/services/robotics" },
    { title: "SPM Manufacturing", link: "/services/spm" },
    { title: "Sales", link: "/services/sales" },
    { title: "Hospital Management", link: "/services/hospital_management" },
  ];

  const height = window.innerHeight;

  return (
    <div
      className="stickyHeader"
      style={{
        flexDirection: "column",
        flex: "1",
        justifyContent: "space-between",
        boxShadow: boxShadow
          ? "rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px"
          : "none",
        padding: "6px 20px",
      }}
    >
      <Modal
        width="100vw"
        height={`${height - 1}px`}
        showModal={showRoutes}
        onClose={() => {
          setShowRoutes(false);
          setCloseModal(false);
        }}
        background={Colors.BigStone}
        borderRadius="0px"
        closeModalOutSideComp={closeModal}
        animationLeftToRight={true}
      >
        <FlexWrapper
          margin={"16px"}
          flexDirection="column"
          width="cal(100vw - 32px)"
          height={`${height - 32}px`}
        >
          <FlexWrapper alignItems={"right"} justifyContent={"right"}>
            <img src={Close} alt="" onClick={() => setCloseModal(true)} />
          </FlexWrapper>
          <FlexWrapper
            flexDirection="column"
            height={`${height - 70}px`}
            overflowY={"scroll"}
          >
            <MarginWrapper mt={"1rem"} mb={"1rem"}>
              <Typography
                text={"QUICK LINKS"}
                sizeAndWeight={"18px-500"}
                color={Colors.White}
              />
            </MarginWrapper>
            <FlexWrapper
              flexDirection="column"
              alignItems={"baseline"}
              flexPercentage={1}
              gap={"24px"}
              margin="8px"
            >
              {navigation.map((route) => {
                return (
                  <div
                    borderBottom={currentPage === route.link ? "2px" : ""}
                    key={route.link}
                  >
                    <Link
                      to={route.link}
                      style={{
                        fontSize: "14px",
                        textDecoration: "none",
                        color: Colors.White,
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        setCloseModal(true);
                        setTimeout(() => {
                          setShowRoutes(false);
                        }, 400);
                      }}
                    >
                      {route.title}
                    </Link>
                  </div>
                );
              })}
            </FlexWrapper>

            <MarginWrapper mt={"1rem"} mb={"1rem"}>
              <Typography
                text={"Services"}
                sizeAndWeight={"18px-500"}
                color={Colors.White}
              />
            </MarginWrapper>
            <FlexWrapper
              flexDirection="column"
              alignItems={"baseline"}
              flexPercentage={1}
              gap={"24px"}
              margin="8px"
            >
              {services.map((route) => {
                return (
                  <div
                    borderBottom={currentPage === route.link ? "2px" : ""}
                    key={route.link}
                  >
                    <Link
                      to={route.link}
                      style={{
                        fontSize: "14px",
                        textDecoration: "none",
                        color: Colors.White,
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        setCloseModal(true);
                        setTimeout(() => {
                          setShowRoutes(false);
                        }, 400);
                      }}
                    >
                      {route.title}
                    </Link>
                  </div>
                );
              })}
            </FlexWrapper>
          </FlexWrapper>
        </FlexWrapper>
      </Modal>
      <FlexWrapper
        flexDirection="row"
        flexPercentage={1}
        justifyContent={"space-between"}
        width="100%"
      >
        <Link
          to={"/"}
          style={{
            fontSize: "16px",
            textDecoration: "none",
            color: Colors.MirageBlack,
            fontWeight: "700",
          }}
        >
          <img src={Logo} alt="logo" width={"120px"} />
        </Link>
        <img
          src={Hamburger}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={() => setShowRoutes(!showRoutes)}
        />
      </FlexWrapper>
    </div>
  );
};

export default MobileNavigation;
