import React from "react";
import FlexWrapper from "../../Atoms/FlexWrapper";

import PageWrapper from "../../Atoms/PageWrapper";
import TeamCard from "../../Atoms/TeamCard";
import Title from "../../Atoms/Title/Title";

import teamDetails from "../../data/teamDetails";
import { Colors } from "../../Utils/Colors";
const Team = () => {
  return (
    <PageWrapper backgroundColor={Colors.BigStone}>
      <Title text={"The Team"} color={Colors.White} />
      <FlexWrapper
        flexDirection="row"
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"10vw"}
        margin={"5vw 0"}
      >
        {teamDetails.map((each, index) => {
          return (
            <TeamCard
              img={each.img}
              name={each.name}
              designation={each.designation}
              linkedLink={each.linkedLink}
              key={index}
            />
          );
        })}
      </FlexWrapper>
    </PageWrapper>
  );
};

export default Team;
