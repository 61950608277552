import React, { useEffect } from "react";
import { images } from "../../Utils/Utils";
import Arrow from "../../Assets/Select.svg";
import { Colors } from "../../Utils/Colors";
import { isMobile } from "../../Utils/IsMobile";

let nodeJsTimer;
const ImageSlider = () => {
  const [imageCounter, setImageCounter] = React.useState(0);
  const [isHover, setIsHover] = React.useState(false);
  useEffect(() => {
    handleImageSlider();
    return () => {
      handleMouseEnter();
    };
  }, []);
  const imageLength = images.length;

  const handleImageSlider = () => {
    setIsHover(false);
    nodeJsTimer = setInterval(() => {
      setImageCounter((previous) => {
        if (imageLength - 1 === previous) {
          return 0;
        }
        return previous + 1;
      });
    }, 2000);
  };

  const handleMouseEnter = () => {
    clearInterval(nodeJsTimer);
    setIsHover(true);
  };

  const handelGoBack = () => {
    setImageCounter((previous) => {
      if (0 === previous) {
        return imageLength - 1;
      }
      return previous - 1;
    });
  };

  const handleGoNext = () => {
    setImageCounter((previous) => {
      if (imageLength - 1 === previous) {
        return 0;
      }
      return previous + 1;
    });
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleImageSlider}
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1",
        maxWidth: "600px",
        height: isMobile ? "250px" : "350px",
      }}
    >
      {isHover ? (
        <div
          style={{
            position: "absolute",
            left: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            bottom: 0,
            top: 0,
          }}
        >
          <img
            src={Arrow}
            alt={""}
            style={{ cursor: "pointer", transform: "rotate(90deg)" }}
            onClick={handelGoBack}
            width={"24px"}
            height={"24px"}
          />
        </div>
      ) : null}
      {isHover ? (
        <div
          style={{
            position: "absolute",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            bottom: 0,
            top: 0,
          }}
        >
          <img
            src={Arrow}
            alt={""}
            width={"24px"}
            height={"24px"}
            style={{ cursor: "pointer", transform: "rotate(270deg)" }}
            onClick={handleGoNext}
          />
        </div>
      ) : null}
      <img src={images[imageCounter]} style={{ maxWidth: "min(500px,40vw)" }} />
    </div>
  );
};

export default ImageSlider;
