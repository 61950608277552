import React, { useEffect, useState } from "react";
import Button from "../../Atoms/Button";
import FlexWrapper from "../../Atoms/FlexWrapper";
import MarginWrapper from "../../Atoms/MarginWrapper";
import PageWrapper from "../../Atoms/PageWrapper";
import Title from "../../Atoms/Title/Title";
import Typography from "../../Atoms/Typography";
import { Colors } from "../../Utils/Colors";
import hexToRGB from "../../Utils/hexToRGBA";
import { isMobile } from "../../Utils/IsMobile";
import allServices from "../../data/servicesCardDetails";
import Lottie from "lottie-react";
import Search from "../../Assets/Select.svg";
import { useLocation } from "react-router-dom";
const Services = () => {
  const [services, setServices] = useState("factory-automation");
  const [showDropdown, setShowDropDown] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const path = window.location.pathname.split("/");
    const exactPath = path[path.length - 1];
    if (exactPath !== "services" && path.includes("services")) {
      setServices(exactPath);
    }
  }, [location]);

  const buttons = [
    { name: "factory-automation", title: "Factory Automation" },
    { name: "iot", title: "Internet of Things" },
    { name: "iot-software", title: "IoT Specialized Software development" },
    { name: "machine-vision", title: "Machine Vision" },
    { name: "robotics", title: "Robotics" },
    { name: "spm", title: "SPM Manufacturing" },
    { name: "sales", title: "Sales" },
    { name: "hospital_management", title: "Hospital Management" },
  ];
  // /DoctorwithNotepad

  const answer = {
    "factory-automation": "Factory Automation",
    iot: "Internet of Things",
    "iot-software": "IOT Specialized Software development",
    "machine-vision": "Machine Vision",
    robotics: "Robotics",
    spm: "SPM Manufacturing",
    sales: "Sales",
    hospital_management: "Hospital Management",
  };

  const serviceDetails = allServices[services];

  const webButtons = (
    <FlexWrapper
      flexDirection={"row"}
      alignItems={"center"}
      flexWrap={"wrap"}
      gap={"20px"}
    >
      {buttons.map((each) => {
        return (
          <div key={each.title}>
            <Button
              onPress={() => {
                setServices(each.name);
              }}
              padding={"12px 24px"}
              background={services === each.name ? Colors.yellow : Colors.White}
              borderRadius={"20px"}
              height={"40px"}
              buttonText={each.title}
              textColor={
                services === each.name
                  ? Colors.MirageBlack
                  : hexToRGB(Colors.MirageBlack, "0.7")
              }
            ></Button>
          </div>
        );
      })}
    </FlexWrapper>
  );

  const mobileButton = (
    <FlexWrapper position={"relative"}>
      <FlexWrapper
        maxWidth={"330px"}
        height={"48px"}
        borderRadius={"4px"}
        cursor={"pointer"}
        padding={"0 12px"}
        onPress={() => {
          setShowDropDown(true);
        }}
        backgroundColor={Colors.White}
        width={"100%"}
        alignItems={"center"}
      >
        <FlexWrapper flexPercentage={1}>
          <Typography
            text={answer[services]}
            color={Colors.MirageBlack}
            sizeAndWeight={"14-400"}
          />
        </FlexWrapper>
        <img src={Search} alt="" style={{ width: "18px", height: "18px" }} />
      </FlexWrapper>
      {showDropdown ? (
        <FlexWrapper
          position={"absolute"}
          flexDirection={"column"}
          top={"52px"}
          backgroundColor={Colors.white}
          width={"100%"}
          maxWidth={"330px"}
          cursor={"pointer"}
        >
          {buttons.map((each) => {
            return (
              <FlexWrapper
                backgroundColor={
                  services === each.name ? Colors.yellow : Colors.White
                }
                height={"40px"}
                key={each.title}
                // width={"100%"}
                // maxWidth={"330px"}
                padding={"0 12px"}
                onPress={() => {
                  setServices(each.name);
                  setShowDropDown(false);
                }}
                alignItems={"center"}
              >
                <Typography
                  text={each.title}
                  color={
                    services === each.name
                      ? Colors.MirageBlack
                      : hexToRGB(Colors.MirageBlack, "0.7")
                  }
                  sizeAndWeight={"14px-400"}
                />
              </FlexWrapper>
            );
          })}
        </FlexWrapper>
      ) : null}
    </FlexWrapper>
  );

  return (
    <PageWrapper backgroundColor={Colors.LightGray}>
      <Title text={"Our Services"} />
      <MarginWrapper mb={isMobile ? "2rem" : "4rem"} />
      {isMobile ? mobileButton : webButtons}
      <MarginWrapper mb={isMobile ? "2rem" : "4rem"} />
      <FlexWrapper
        flexDirection={isMobile ? "column" : "row"}
        alignItems={"center"}
        justifyContent={"center"}
        flexPercentage={1}
        gap={"40px"}
      >
        <FlexWrapper
          flexPercentage={1}
          flexDirection={"column"}
          maxWidth={"600px"}
        >
          <FlexWrapper gap={"12px"} flexDirection={"column"}>
            {serviceDetails.content.map((each) => {
              return (
                <Typography
                  key={each}
                  text={each}
                  color={Colors.MirageBlack}
                  sizeAndWeight={"16px-400"}
                />
              );
            })}
          </FlexWrapper>
          {serviceDetails.headerWithElements ? (
            <FlexWrapper
              gap={"12px"}
              flexDirection={"column"}
              margin={"20px 0 0 0"}
            >
              {serviceDetails.headerWithElements.map((each, index) => {
                return (
                  <FlexWrapper flexDirection={"column"} key={index}>
                    <Typography
                      text={`${each.header}`}
                      color={Colors.MirageBlack}
                      sizeAndWeight={"14px-400"}
                    />
                    <FlexWrapper
                      flexDirection={"row"}
                      gap={"4px"}
                      flexWrap={"wrap"}
                    >
                      {each.elements.map((eachElements, index) => {
                        return (
                          <Typography
                            key={`${index + 1} ${eachElements}`}
                            text={`${index + 1} ${eachElements}`}
                            color={Colors.MirageBlack}
                            sizeAndWeight={"14px-400"}
                          />
                        );
                      })}
                    </FlexWrapper>
                  </FlexWrapper>
                );
              })}
            </FlexWrapper>
          ) : null}
          {serviceDetails.points ? (
            <FlexWrapper
              gap={"4px"}
              flexDirection={"column"}
              margin={"20px 0 0 0"}
            >
              {serviceDetails.points.map((each, index) => {
                return (
                  <Typography
                    key={`${index + 1} ${each}`}
                    text={`${index + 1} ${each}`}
                    color={Colors.MirageBlack}
                    sizeAndWeight={"14px-400"}
                  />
                );
              })}
            </FlexWrapper>
          ) : null}
        </FlexWrapper>

        <FlexWrapper
          alignItems={"center"}
          justifyContent={"center"}
          flexPercentage={1}
          maxWidth={"600px"}
          width={"100%"}
        >
          {serviceDetails?.reactNode ? (
            serviceDetails.reactNode
          ) : serviceDetails.isLoftilyExists ? (
            <div style={{ height: "500px" }}>
              <Lottie animationData={serviceDetails.animation} />
            </div>
          ) : (
            <img
              src={serviceDetails.imgSrc}
              style={{ maxWidth: "min(500px,40vw)" }}
            />
          )}
        </FlexWrapper>
      </FlexWrapper>
      <MarginWrapper mb={isMobile ? "2rem" : "4rem"} />
    </PageWrapper>
  );
};

export default Services;
