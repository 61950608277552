import React, { useState } from "react";
import { Colors } from "../../Utils/Colors";
import { isMobile } from "../../Utils/IsMobile";
import {
  Experience,
  PersonalSolution,
  StrongTeam,
} from "../../Utils/SVGImages";
import FlexWrapper from "../FlexWrapper";
import MarginWrapper from "../MarginWrapper";
import Typography from "../Typography";

const WhyChooseUsCard = ({ title, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  const returnImageSource = () => {
    if (title === "Big experience") {
      return <Experience fill={Colors.MirageBlack} />;
    }
    if (title === "Strong team") {
      return <StrongTeam fill={Colors.MirageBlack} />;
    }
    if (title === "Personal solutions") {
      return <PersonalSolution fill={Colors.MirageBlack} />;
    }
  };

  return (
    <FlexWrapper
      flexDirection="column"
      gap={"24px"}
      padding={"24px"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      border={`1px solid ${Colors.Grey}`}
      alignItems={"center"}
      justifyContent={"center"}
      maxWidth={"300px"}
      borderRadius={"12px"}
    >
      <div
        style={{
          borderRadius: "50%",
          width: "42px",
          height: "42px",
          padding: "4px",
          border: `1px solid ${Colors.MirageBlack}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {returnImageSource()}
      </div>
      <div>
        <Typography
          text={title}
          color={Colors.MirageBlack}
          sizeAndWeight={isMobile ? "16px-500" : "20px-500"}
          textAlign={"center"}
        />
        <MarginWrapper mt={"20px"}>
          <Typography
            text={description}
            color={"rgba(0,0,0,0.7)"}
            sizeAndWeight={isMobile ? "14px-400" : "16px-400"}
            textAlign={"center"}
          />
        </MarginWrapper>
      </div>
    </FlexWrapper>
  );
};

export default WhyChooseUsCard;
