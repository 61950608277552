import CEO from "../Assets/CEO.png";
const teamDetails = [
  {
    img: CEO,
    name: "Cherukuri Rajesh",
    designation: "Founder",
    linkedLink: "https://www.linkedin.com/in/cherukuri-rajesh-37286728/",
  },
];
export default teamDetails;
