import React from "react";
import FlexWrapper from "../../Atoms/FlexWrapper";
import PageWrapper from "../../Atoms/PageWrapper";
import Title from "../../Atoms/Title/Title";
import WhyChooseUsCard from "../../Atoms/WhyChooseUsCard";

import { Colors } from "../../Utils/Colors";
import ChooseUsDenials from "../../data/whyChooseUs";

const ChooseUs = () => {
  return (
    <PageWrapper backgroundColor={Colors.LightGray}>
      <Title text={"Why Choose Us"} />
      <FlexWrapper
        flexDirection={"row"}
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"5vw"}
        margin={"6vw 0"}
      >
        {ChooseUsDenials.map((each,index) => {
          return (
            <WhyChooseUsCard
              title={each.title}
              description={each.description}
              key={index}
            />
          );
        })}
      </FlexWrapper>
    </PageWrapper>
  );
};

export default ChooseUs;
