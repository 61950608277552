import React, { useEffect, useRef, useState } from "react";

import "./Modal.css";
import CloseModalIcon from "../../Assets/XMark.svg";

const Modal = ({
  onClose,
  height = "200px",
  width = "200px",
  borderRadius = "16px",
  showCloseIcon = false,
  showModal,
  children,
  background,
  closeModalOutSideComp,
  animationLeftToRight,
}) => {
  const [modal, setModal] = useState(showModal);

  const onCloseIconClick = () => {
    setModal(true);
    setTimeout(() => {
      onClose(false);
      setModal(false);
    }, 500);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  useEffect(() => {
    if (closeModalOutSideComp) {
      onCloseIconClick();
    }
  }, [closeModalOutSideComp]);

  const animation = () => {
    if (animationLeftToRight) {
      return modal ? "animationLeftToRight" : "animationRightToLeft";
    } else {
      return modal ? "animationFromBottomToTop" : "animationFromTopToBottom";
    }
  };
  if (showModal) {
    return (
      <div className={"commonStyles"}>
        <div className={`modal ${animation()}`}>
          <div
            ref={modalRef}
            style={{ width: width, height: height, borderRadius, background }}
          >
            {showCloseIcon ? (
              <div style={{ position: "relative" }}>
                <img
                  src={CloseModalIcon}
                  alt="CloseModalIcon"
                  className="closeModal"
                  onClick={onCloseIconClick}
                />
              </div>
            ) : null}
            {children}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Modal;
