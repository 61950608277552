import React from "react";

import PageWrapper from "../../Atoms/PageWrapper";
import { Colors } from "../../Utils/Colors";
import Title from "../../Atoms/Title/Title";
import MarginWrapper from "../../Atoms/MarginWrapper";
import { isMobile } from "../../Utils/IsMobile";
import Carousel from "../../Organism/Carousel/Carousel";

const Testimonials = () => {
  return (
    <PageWrapper backgroundColor={Colors.LightGray}>
      <Title text={"what our customers say"} />
      <MarginWrapper mb={isMobile ? "2rem" : "4rem"} />
      <Carousel />
      <MarginWrapper mb={isMobile ? "2rem" : "4rem"} />
    </PageWrapper>
  );
};

export default Testimonials;
