import React, { useState } from "react";
import FlexWrapper from "../../Atoms/FlexWrapper";
import Input from "../../Atoms/Input/Input";
import MarginWrapper from "../../Atoms/MarginWrapper";
import Typography from "../../Atoms/Typography";
import { Colors } from "../../Utils/Colors";
import Button from "../../Atoms/Button";
import "./GetInTouch.css";

import GIF from "../../Lottie/email.gif";
import Modal from "../../Atoms/Modal";

import emailkey, { anotherEmail } from "../../Utils/emailkey";
const GetInTouch = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState(false);

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleNameChange = (value) => {
    setName(value);
    if (value === "") {
      setNameError("Required");
    } else {
      setNameError("");
    }
  };

  const handelEmailChange = (value) => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (value !== "" && !emailRegex.test(value)) {
      setEmailError("Please Enter Valid Email Id");
    } else {
      setEmailError("");
    }
    setEmail(value);
  };

  const handelNumberChange = (value) => {
    const numberRegex = /[^0-9]/g;
    if (!numberRegex.test(value)) {
      value.length === 10 || value.length === 0
        ? setPhoneNumberError("")
        : setPhoneNumberError("Phone number must be 10 digits");
      setPhoneNumber(value);
    }
  };

  const handleMessageChange = (value) => {
    setMessage(value);
    if (value === "") {
      setMessageError("Required");
    } else {
      setMessageError("");
    }
  };

  const handelDisable = () => {
    return (
      nameError !== "" ||
      emailError !== "" ||
      phoneNumberError !== "" ||
      messageError !== "" ||
      name === "" ||
      message === ""
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const inputFields = {
      ToName: "Rajesh",
      Name: name,
      PhoneNumber: phoneNumber,
      UserEmail: email,
      Subject: subject,
      Message: message,
    };

    var data = {
      service_id: emailkey.service_id,
      template_id: emailkey.template_id,
      user_id: emailkey.user_id,
      template_params: inputFields,
    };
    var dataCopy = {
      service_id: anotherEmail.service_id,
      template_id: anotherEmail.template_id,
      user_id: anotherEmail.user_id,
      template_params: inputFields,
    };

    setLoader(true);
    fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataCopy),
    });
    fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then((res) => {
      setLoader(false);
      setOpenModal(true);
      setName("");
      setNameError("");
      setPhoneNumber("");
      setPhoneNumberError("");
      setEmailError("");
      setEmail("");
      setSubject("");
      setMessage("");
      setMessageError("");
    });
  };

  const isMobile = window.innerWidth <= 600;

  return (
    <div
      style={{
        border: isMobile ? "none" : "1px solid rgba(255, 255, 255, 0.3)",
        padding: isMobile ? "0px" : "24px",
        borderRadius: "16px",
      }}
    >
      <Modal
        onClose={() => setOpenModal(false)}
        showModal={openModal}
        width={isMobile ? "320px" : "400px"}
        height={isMobile ? "310px" : "400px"}
        borderRadius="20px"
        background="#FFF"
        showCloseIcon={true}
      >
        <FlexWrapper flexDirection={"column"} alignItems={"center"}>
          <img
            src={GIF}
            alt=""
            width={isMobile ? "200px" : "300px"}
            height={isMobile ? "200px" : "300px"}
          />
          <MarginWrapper mt={"10px"} mb={"10px"} ml={"20px"} mr={"20px"}>
            <Typography
              text={
                "Thankyou for reaching to us, our support team will reach out to you as soon as possible."
              }
              color={Colors.BigStone}
              sizeAndWeight={"16px-500"}
              textAlign={"center"}
            />
          </MarginWrapper>
        </FlexWrapper>
      </Modal>
      <Typography
        text="Got any questions?"
        color={Colors.White}
        sizeAndWeight={isMobile ? "1.2rem-500" : "1.8rem-500"}
      />
      <MarginWrapper mb={"2px"} />
      <Typography
        text="Please fill the below details"
        color={Colors.White}
        sizeAndWeight={isMobile ? "0.8rem-500" : "1.5rem-500"}
      />
      <FlexWrapper gap={"24px"} flexDirection="column" margin="30px 0 ">
        <Input
          label="Name"
          isRequired={true}
          value={name}
          errorMessage={nameError}
          onChange={(value) => handleNameChange(value)}
        />
        <Input
          label="Phone Number"
          isRequired={true}
          value={phoneNumber}
          errorMessage={phoneNumberError}
          onChange={(value) => handelNumberChange(value)}
        />
        <Input
          label="Email"
          isRequired={true}
          value={email}
          errorMessage={emailError}
          onChange={(value) => handelEmailChange(value)}
        />
        <Input
          label="Subject"
          isRequired={false}
          value={subject}
          errorMessage={subjectError}
          onChange={(value) => setSubject(value)}
        />
        <FlexWrapper flexPercentage={1} flexDirection="column">
          <FlexWrapper>
            <Typography
              text={"Message"}
              color={Colors.White}
              sizeAndWeight={"14px-500"}
            />
            <Typography
              text={`*`}
              color={Colors.Red}
              sizeAndWeight={"14px-500"}
            />
          </FlexWrapper>
          <textarea
            value={message}
            onChange={(e) => handleMessageChange(e.target.value)}
            className="textarea"
            rows={3}
          />
        </FlexWrapper>

        <MarginWrapper mt="10px">
          <Button
            buttonText={"Submit"}
            background={Colors.White}
            onPress={handleSubmit}
            disable={handelDisable()}
            width={"120px"}
            height={"40px"}
            borderRadius={"24px"}
            isLoading={loader}
          ></Button>
        </MarginWrapper>
      </FlexWrapper>
    </div>
  );
};

export default GetInTouch;
