import React from "react";

const MarginWrapper = ({
  mt = undefined,
  mb = undefined,
  ml = undefined,
  mr = undefined,
  children = undefined,
}) => {
  return (
    <div
      style={{
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
      }}
    >
      {children}
    </div>
  );
};

export default MarginWrapper;
