import React from "react";
import { Link, useLocation } from "react-router-dom";

import FlexWrapper from "../FlexWrapper";
import MobileNavigation from "./MobileNavigation";

import { Colors } from "../../Utils/Colors";
import Logo from "../../Assets/logoWithOutCaption.jpg";
import "./Header.css";
import { isMobile } from "../../Utils/IsMobile";
const Header = () => {
  let location = useLocation();
  const [currentPage, setCurrentPage] = React.useState(location.pathname);
  const [shadow, setShadow] = React.useState(true);

  React.useEffect(() => {
    const loc = location.pathname;
    setCurrentPage(loc.includes("services") ? "/services" : loc);
    window.scrollTo(0, 0);
  }, [location]);

  const navigation = [
    { title: "Home", link: "/" },
    { title: "About", link: "/about" },
    { title: "Services", link: "/services" },
    { title: "Contact Us", link: "/contact-us" },
  ];

  return (
    <>
      {isMobile ? (
        <MobileNavigation currentPage={currentPage} boxShadow={shadow} />
      ) : (
        <div
          className="stickyHeader"
          style={{
            boxShadow: shadow
              ? "rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px"
              : "none",
            padding: "6px 3em",
          }}
        >
          <Link
            to={"/"}
            style={{
              fontSize: "16px",
              textDecoration: "none",
              color: Colors.MirageBlack,
              fontWeight: "700",
            }}
          >
            <img src={Logo} alt="logo" width={"140px"} />
          </Link>
          <FlexWrapper
            flexDirection="row"
            alignItems={"baseline"}
            flexPercentage={1}
            justifyContent={"right"}
            gap={"40px"}
          >
            {navigation.map((route) => {
              const doesMatches = currentPage === route.link;
              return (
                <div
                  borderBottom={"2px"}
                  key={route.link}
                  style={{
                    borderBottom: doesMatches
                      ? `2px solid ${Colors.JungleGreen}`
                      : "2px",
                  }}
                >
                  <Link
                    to={route.link}
                    style={{
                      fontSize: "16px",
                      textDecoration: "none",
                      color: Colors.MirageBlack,
                      fontWeight: "700",
                    }}
                  >
                    {route.title}
                  </Link>
                </div>
              );
            })}
          </FlexWrapper>
        </div>
      )}
    </>
  );
};

export default Header;
