import React from "react";
import { Colors } from "../../Utils/Colors";
import MarginWrapper from "../MarginWrapper";
import Typography from "../Typography";

const HistoryCard = ({ month, year, achievement, alignLeft }) => {
  return (
    <MarginWrapper mt={"20px"} mb={"20px"}>
      <Typography
        text={`${month} ${year}`}
        color={Colors.PictonBlue}
        sizeAndWeight={"2rem-500"}
        textAlign={alignLeft ? "end" : "start"}
      />
      <Typography
        text={achievement}
        color={Colors.MirageBlack}
        sizeAndWeight={"1rem-400"}
        textAlign={alignLeft ? "end" : "start"}
      />
    </MarginWrapper>
  );
};

export default HistoryCard;
