import React from "react";

import FlexWrapper from "../../Atoms/FlexWrapper";
import HistoryCard from "../../Atoms/HistoryCard";
import MarginWrapper from "../../Atoms/MarginWrapper";
import PageWrapper from "../../Atoms/PageWrapper";
import Title from "../../Atoms/Title/Title";

import briefHistoryDetails from "../../data/briefHistoryDetails";
import { Colors } from "../../Utils/Colors";
import hexToRGB from "../../Utils/hexToRGBA";
import { isMobile } from "../../Utils/IsMobile";
import { Triangle } from "../../Utils/SVGImages";

import "./BriefHistory.css";

const BriefHistory = () => {
  const returnColors = (index) => {
    if (index === 0) {
      return Colors.JungleGreen;
    }
    return Colors.yellow;
  };

  return (
    <PageWrapper backgroundColor={Colors.LightGray}>
      <Title text={"A BRIEF HISTORY"} />
      <MarginWrapper mt={"6vw"} mb={"6vw"}>
        {isMobile ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "flex-start",
                background: hexToRGB(Colors.MirageBlack, "0.3"),
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                margin: "0 41px",
              }}
            />
            <div className="briefHistoryGrid">
              {briefHistoryDetails.map((each, index) => {
                const isEven = index % 2 === 0;
                const color = returnColors(index);
                return (
                  <FlexWrapper flexDirection="row" key={index}>
                    <FlexWrapper
                      alignItems={"center"}
                      justifyContent={"center"}
                      position={"relative"}
                      margin={"0 30px"}
                    >
                      <div
                        style={{
                          display: "flex",
                          borderLeft: `2px solid ${hexToRGB(
                            Colors.MirageBlack,
                            "0.3"
                          )}`,
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      />
                      <div
                        style={{
                          background: "#FFF",
                          width: "30px",
                          height: "30px",
                          borderRadius: "20px",
                          position: "absolute",
                          zIndex: 1,
                        }}
                      />
                      <div
                        style={{
                          background: hexToRGB(color, "0.4"),
                          width: "30px",
                          height: "30px",
                          borderRadius: "20px",
                          position: "absolute",
                          zIndex: 1,
                        }}
                      />
                      <div
                        style={{
                          background: color,
                          width: "22px",
                          height: "22px",
                          borderRadius: "20px",
                          position: "absolute",
                          zIndex: 1,
                        }}
                      />
                    </FlexWrapper>
                    <HistoryCard
                      month={each.month}
                      year={each.year}
                      achievement={each.achievement}
                      alignLeft={false}
                    />
                  </FlexWrapper>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "12px",
                margin: "-4px 41px",
              }}
            >
              <Triangle fill={hexToRGB(Colors.MirageBlack, "0.4")} />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: hexToRGB(Colors.MirageBlack, "0.3"),
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                flex: 1,
                margin: "auto",
              }}
            />
            <div className="briefHistoryGrid">
              {briefHistoryDetails.map((each, index) => {
                const isEven = index % 2 === 0;
                const color = returnColors(index);
                return (
                  <FlexWrapper
                    flexPercentage={1}
                    flexDirection="row"
                    justifyContent={"space-between"}
                    key={index}
                  >
                    <FlexWrapper flexPercentage={1} justifyContent={"flex-end"}>
                      {isEven && (
                        <HistoryCard
                          month={each.month}
                          year={each.year}
                          achievement={each.achievement}
                          alignLeft={isEven}
                        />
                      )}
                    </FlexWrapper>
                    <FlexWrapper
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexPercentage={1}
                      position={"relative"}
                      margin={"0 30px"}
                    >
                      <div
                        style={{
                          display: "flex",
                          borderLeft: `2px solid ${hexToRGB(
                            Colors.MirageBlack,
                            "0.3"
                          )}`,
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      />
                      <div
                        style={{
                          background: "#FFF",
                          width: "30px",
                          height: "30px",
                          borderRadius: "20px",
                          position: "absolute",
                          zIndex: 1,
                        }}
                      />
                      <div
                        style={{
                          background: hexToRGB(color, "0.4"),
                          width: "30px",
                          height: "30px",
                          borderRadius: "20px",
                          position: "absolute",
                          zIndex: 1,
                        }}
                      />
                      <div
                        style={{
                          background: color,
                          width: "22px",
                          height: "22px",
                          borderRadius: "20px",
                          position: "absolute",
                          zIndex: 1,
                        }}
                      />
                      <div
                        style={{
                          width: "50%",
                          position: "absolute",
                          border: `1px solid ${hexToRGB(
                            Colors.MirageBlack,
                            "0.3"
                          )}`,
                          left: isEven ? 0 : undefined,
                          right: !isEven ? 0 : undefined,
                        }}
                      />
                    </FlexWrapper>

                    <FlexWrapper flexPercentage={1}>
                      {!isEven && (
                        <HistoryCard
                          month={each.month}
                          year={each.year}
                          achievement={each.achievement}
                          alignLeft={!isEven}
                        />
                      )}
                    </FlexWrapper>
                  </FlexWrapper>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "12px",
                margin: "-2px auto",
                flex: 1,
              }}
            >
              <Triangle fill={hexToRGB(Colors.MirageBlack, "0.4")} />
            </div>
          </>
        )}
      </MarginWrapper>
    </PageWrapper>
  );
};

export default BriefHistory;
