import React from "react";
import Button from "../../Atoms/Button";
import FlexWrapper from "../../Atoms/FlexWrapper";
import PageWrapper from "../../Atoms/PageWrapper";
import Title from "../../Atoms/Title/Title";
import Typography from "../../Atoms/Typography";
import { Colors } from "../../Utils/Colors";
import { isMobile } from "../../Utils/IsMobile";
import Logo from "../../Assets/headerLogo.png";
import { useHistory } from "react-router-dom";

const Introduction = () => {
  const history = useHistory();
  return (
    <PageWrapper>
      <FlexWrapper
        flexDirection={isMobile ? "column" : "row"}
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"10vw"}
        margin={"6vw 0"}
      >
        <FlexWrapper
          flexDirection="column"
          gap={"24px"}
          flexPercentage={"1"}
          alignItems={isMobile ? "center" : "flex-start"}
        >
          <Typography
            text={"An innovator for your Business needs"}
            color={Colors.BigStone}
            sizeAndWeight={isMobile ? "1.2rem-500" : "2rem-500"}
            textAlign={isMobile ? "center" : "start"}
            maxWidth={isMobile ? "220px" : "100%"}
          />
          <Typography
            text={
              "Our aphorism is Dream, Create, Inspire.\nWe strongly believe, Innovation is the key to everything the future can be. "
            }
            color={Colors.BigStone}
            sizeAndWeight={isMobile ? "1rem-400" : "1rem-400"}
            textAlign={isMobile ? "center" : "start"}
            whiteSpace={"pre-wrap"}
            maxWidth={isMobile ? "400px" : "100%"}
          />
          <Button
            buttonText={"Explore our services"}
            background={Colors.Red}
            textColor={Colors.White}
            height="45px"
            sizeAndWeight={"16-500"}
            borderColor={"20px"}
            borderRadius={"8px"}
            width={"180px"}
            margin={"0"}
            onPress={() => history.push("/services")}
          />
        </FlexWrapper>
        <FlexWrapper
          flexPercentage={1}
          maxWidth={isMobile ? "450px" : "600px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <img src={Logo} alt="" width={"90%"} />
        </FlexWrapper>
      </FlexWrapper>
    </PageWrapper>
  );
};

export default Introduction;
