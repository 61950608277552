import React from "react";
import { Colors } from "../../Utils/Colors";
import FlexWrapper from "../FlexWrapper";
import Typography from "../Typography";
import "./Input.css";

const Input = ({ value, onChange, errorMessage, label, isRequired }) => {
  return (
    <FlexWrapper flexDirection="column" flexPercentage={1}>
      <FlexWrapper>
        <Typography
          text={`${label}`}
          color={Colors.White}
          sizeAndWeight={"14px-500"}
        />
        {isRequired ? (
          <Typography
            text={`*`}
            color={Colors.Red}
            sizeAndWeight={"14px-500"}
          />
        ) : null}
      </FlexWrapper>
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="inputStyles"
      />
      {errorMessage ? (
        <Typography
          text={errorMessage}
          color={Colors.Red}
          sizeAndWeight={"12px-500"}
        />
      ) : null}
    </FlexWrapper>
  );
};

export default Input;
