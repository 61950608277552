import * as React from "react";
import { Colors } from "../../Utils/Colors";

/**
 * renders the div with the given text
 * @summary
 * it renders text of different sizes, weights and color  based on the props
 * @param props
 * @returns return jsx element div with the given text
 */
const Typography = ({
  sizeAndWeight,
  color = Colors.MirageBlack,
  text,
  fontFamily = "Poppins",
  textAlign = undefined,
  alignSelf = "initial",
  whiteSpace = undefined,
  cursor = undefined,
  wordBreak = undefined,
  maxWidth,
}) => {
  const [size, weight] = sizeAndWeight.split("-");

  return (
    <div
      style={{
        color,
        fontSize: `${size}`,
        fontWeight: parseInt(weight),
        fontFamily: fontFamily,
        textAlign,
        alignSelf,
        whiteSpace,
        cursor,
        wordBreak,
        maxWidth,
      }}
    >
      {text}
    </div>
  );
};

export default Typography;
