export const Colors = {
    MirageBlack: "#14142B",
    White: "#FFFFFF",
    JungleGreen: "#27AE60",
    BigStone: "#161B40",
    PictonBlue: "#43bee5",
    yellow: "#EFA500",
    Grey: " rgba(178,186,181,.4)",
    Red: "#F40058",
    LightGray: "#f2f6fa",
};