const whyChooseUS = [{
        title: "Big experience",
        description: "Many years of work in this field is an excellent indicator that companies trust us and in response we offer unique solutions.",
    },
    {
        title: "Strong team",
        description: "An excellent team of professionals will help you to bring all your ideas to life in the best possible way and with flexible functionality.",
    },
    {
        title: "Personal solutions",
        description: "Individual approach to your project. flexible solutions for your tasks to achieve your goals on the path to success.",
    },
];

export default whyChooseUS;