import React from "react";

const FlexWrapper = ({
  flexDirection = "row",
  alignItems,
  justifyContent,
  children,
  flexWrap = undefined,
  flexPercentage = undefined,
  gap = undefined,
  width = undefined,
  height = undefined,
  minHeight,
  margin = undefined,
  maxWidth = undefined,
  position,
  backgroundColor = undefined,
  padding,
  display = "flex",
  top = undefined,
  bottom = undefined,
  borderRadius = undefined,
  border,
  onMouseEnter,
  onMouseLeave,
  overflowY,
  onPress,
  cursor

}) => {
  return (
    <div
      style={{
        display: display,
        flexDirection: flexDirection,
        alignItems: alignItems,
        justifyContent: justifyContent,
        flexWrap: flexWrap,
        flex: flexPercentage,
        gap,
        width,
        height,
        margin,
        maxWidth,
        position,
        backgroundColor,
        padding,
        minHeight,
        top,
        bottom,
        borderRadius,
        border,
        overflowY,
        cursor
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onPress}
    >
      {children}
    </div>
  );
};

export default FlexWrapper;
