import React from "react";
import { Colors } from "../../Utils/Colors";
import { isMobile } from "../../Utils/IsMobile";
import FlexWrapper from "../FlexWrapper";
import Typography from "../Typography";

const Title = ({ text, color }) => {
  return (
    <FlexWrapper
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection="column"
      gap={"0"}
      position={"relative"}
    >
      <Typography
        text={text}
        color={color ?? Colors.BigStone}
        sizeAndWeight={isMobile ? "2rem-400" : "3rem-400"}
        textAlign={"center"}
      />
    </FlexWrapper>
  );
};

export default Title;
