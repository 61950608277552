import React, { useState } from "react";

import FlexWrapper from "../FlexWrapper";
import Typography from "../Typography";

import { Colors } from "../../Utils/Colors";
import { isMobile } from "../../Utils/IsMobile";
import { LinkedIn } from "../../Utils/SVGImages";
import hexToRGB from "../../Utils/hexToRGBA";

const TeamCard = ({ img, name, designation, linkedLink }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      style={{
        width: "240px",
        height: "370px",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <img
        src={img}
        alt=""
        width="240px"
        height="370px"
        style={{ borderRadius: "6px" }}
      />
      <FlexWrapper
        backgroundColor={"rgba(255,255,255)"}
        flexDirection="column"
        gap={"6px"}
        alignItems={"center"}
        justifyContent={"center"}
        position={"absolute"}
        bottom={"0"}
        width={"100%"}
        padding={"4px 0"}
        borderRadius={"0 0 6px 6px"}
      >
        <Typography
          text={name}
          color={Colors.MirageBlack}
          sizeAndWeight={isMobile ? "1rem-500" : "1.2rem-500"}
        />
        <Typography
          text={designation}
          color={hexToRGB(Colors.MirageBlack, "0.7")}
          sizeAndWeight={isMobile ? "1rem-500" : "1.2rem-500"}
        />
      </FlexWrapper>

      <div
        style={{
          position: "absolute",
          right: "8px",
          bottom: "0px",
          cursor: "pointer",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => window.open(linkedLink, "_blank")}
      >
        <LinkedIn fill={isHovered ? Colors.BigStone : "#646464"} />
      </div>
    </div>
  );
};

export default TeamCard;
