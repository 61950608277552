import React from "react";
import { Colors } from "../Utils/Colors";
import {
  IOT,
  FactoryAutomation,
  Software,
  Robotics,
  SPMManufacturing,
  MachineVision,
} from "../Utils/SVGImages";
import factoryAnimation from "../Lottie/factoryAnimation.json";
import Iot from "../Lottie/iot.json";
import software from "../Lottie/software.json";
import Lion from "../Assets/MakeInIndia.png";
import Vision from "../Assets/Vision.png";
import ImageSlider from "../Organism/ImageSlider";
import DoctorwithNotepad from "../Lottie/DoctorwithNotepad.json";

const allServices = {
  "factory-automation": {
    isLoftilyExists: true,
    animation: factoryAnimation,
    imgSrc: "",
    content: [
      "Our main core is Factory Automation, We will automate Industries Production lines and Spm machines tailored to satisfy customer needs",
      "We will automate through our Industrial controllers like PLC and CNC",
    ],
    headerWithElements: [
      {
        header: "We have expertise in Industrial verticals like",
        elements: [
          "Pharma Industries",
          "Food Processing Industries",
          "Machine Tool Industries",
          "Plastic Industries",
        ],
      },
      {
        header:
          "We are flexible to do all Make of PLC’s ,HMI’s, VFD’s, Servo’s as",
        elements: [
          "Siemens",
          "Delta",
          "Allen-Bradley",
          "ABB",
          "Schneider",
          "Mitsubishi",
          "Panasonic",
        ],
      },
      { header: "CNC’s expertise in", elements: ["Fagor", "Fanuc", "Siemens"] },
    ],
    reactNode: null,
  },
  iot: {
    isLoftilyExists: true,
    animation: Iot,
    imgSrc: "",
    content: [
      "We are delivering customized Industry 4.0 solutions through Internet of Things. We have expertise in data collection from ﬁeld devices like PLC,CNC and sensors and push data to cloud to analyze. We practiced deploying ML (Machine Learning ) algorithms to generate predictions of Machine failure.",
      "Through this we will increase Machine efficiency and performance",
      "The Internet of Things (IoT) brings the internet's power, data processing, and analytics to the real world of physical objects.",
      "Consumers will be able to interact with the global information network without the use of a keyboard and screen; many of their everyday objects and appliances will be able to take instructions from the network with minimal human intervention.",
    ],
    reactNode: null,
  },
  "iot-software": {
    isLoftilyExists: true,
    animation: software,
    imgSrc: "",
    content: [
      "Day by Day, on increasing IoT devices we emerged into IT platform to develop web based and Mobile Interfaces. We are developing IoT software platforms in part of Industrial digital transformation.",
      "We will develop web-based application globally access the data of machines and devices. As well as mobile applications both Android/IOS.",
      "IoT software engineering refers to the systematic development of IoT infrastructure using a blend of software and hardware solutions. IoT software engineering deals with data collected via sensors and processes it to make sense for real-world applications with intuitive UI and visual representation.",
    ],
    reactNode: null,
  },
  spm: {
    isLoftilyExists: false,
    animation: null,
    imgSrc: Lion,
    content: [
      "We have large workshop to manufacture machines. We mainly focused on SPM (Special Purpose Machines).",
      "Our highly qualiﬁed and experienced automation experts design and develop assembly automation across a range of industries including pharmaceutical, medical device, food & beverage, consumer goods, and other manufacturing industries.",
      "On top of that, the Indian manufacturers have significantly evolved in the domain and are known to deliver supreme quality machines. One such special purpose machine manufacturer is Zeit Systems . Zeits team designs the special purpose machines to cater to industries like Manufacturing, Medical, Automotive. Opting for Zeit’s Special Purpose Machine will help you to fight against the challenges like – Scarcity of labor, lack of skilled employees, quality of the process as well as production, expensive raw materials, lack of safety, etc.",
    ],
    reactNode: null,
  },
  robotics: {
    isLoftilyExists: true,
    animation: factoryAnimation,
    imgSrc: "",
    content: [
      "Robots and Manufacturing are a natural partnership. Robotics play a major role in the manufacturing landscape today. Automated manufacturing solutions should be a key part of any operation that strives for maximum efficiency, safety and competitive advantage in the market. Manufacturing robots automate repetitive tasks, reduce margins of error to negligible rates, and enable human workers to focus on more productive areas.",
      "We offered our services to Giants like ABB, KUKA, Yaskawa, Fanuc",
    ],
    reactNode: null,
  },
  "machine-vision": {
    isLoftilyExists: false,
    animation: null,
    imgSrc: Vision,
    content: [
      "Machine Vision comes under Quality Automation. Inspection of products manually replaced by Vision Cameras. Quality impacts the entire product lifecycle throughout design, manufacturing, and most importantly, the customer experience. Centralized access to intelligent data enables collaboration across an entire organization and creates an environment where the critical task of improving product quality can become a core principle in the business.",
    ],
    points: [
      "Increase production yield and reduce scrap, waste, and rework",
      "Advance sustainability efforts with lower water and energy use, send less waste to landfills",
      "Increase production line throughput, decrease energy use",
      "Increase end-product quality and improve customer satisfaction, margins, and brand value.",
    ],
    reactNode: null,
  },
  sales: {
    isLoftilyExists: false,
    animation: null,
    imgSrc: Vision,
    content: [
      "Zeit Systems offers automation products and solutions with high performance and reliability, including drives, motion control systems, industrial control and communication, power quality improvement, human machine interfaces, sensors, meters, and robot solutions. ",
      "All Automation and Electrical related products we are providing at the best price with warranted services.",
    ],
    reactNode: <ImageSlider />,
  },
  hospital_management: {
    isLoftilyExists: true,
    animation: DoctorwithNotepad,
    imgSrc: "",
    content: [
      "Hospital Management Software is an integrated solution for medical businesses, covering patient registration, appointment, medical billing, and HR/payroll, OPD and IPD management.",
      "It can be customized to meet the specific needs of hospitals, and provides relevant information for effective decision-making in patient care and financial accounting.",
      "The software is modular, addressing different hospital activities and ensuring future scalability. It eliminates errors caused by handwriting and improves the retrieval of patient information"
    ],
    reactNode: null,
  },
};

export default allServices;
